import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

import SuiTypography from 'components/SuiTypography';
import SuiBadgeDot from 'components/SuiBadgeDot';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

function RecruitmentPositionCard({
  key,
  position,
  client,
  location,
  date,
  tags,
  openFunction,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  return (
    <Card key={key}>
      <CardActionArea onClick={openFunction}>
        <Grid
          container
          direction="row"
          p={2}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Grid item md={9}>
            <SuiTypography
              fontWeight="bold"
              variant="h5"
            >
              {position}
            </SuiTypography>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                {location !== '' && (
                  <SuiTypography
                    variant="subtitle1"
                    fontWeight="regular"
                  >
                    <FontAwesomeIcon icon={faLocationDot} />
                    {' '}
                    {location}
                  </SuiTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={9}>
            <SuiTypography
              variant="subtitle1"
              fontWeight="regular"
            >
              {client}
            </SuiTypography>
          </Grid>
          <Grid item md={3}>
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <SuiTypography
                  variant="subtitle2"
                  fontWeight="regular"
                >
                  {`${t('opened-on')}: ${date}`}
                </SuiTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Stack spacing={2} direction="row">
              {Object.keys(tags).length > 0
                && tags.map((tag) => (
                  <SuiBadgeDot badgeContent={tag} size="md" />
                ))}
            </Stack>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

RecruitmentPositionCard.defaultProps = {
  key: '',
  location: '',
  date: '',
  tags: [],
  openFunction: null,
};

RecruitmentPositionCard.propTypes = {
  key: PropTypes.string,
  position: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  location: PropTypes.string,
  date: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  openFunction: PropTypes.func,
};

export default RecruitmentPositionCard;
