// eslint-disable-next-line import/prefer-default-export
import {
  createCandidateImmigration,
  updateCandidateImmigration,
} from 'reducers/candidatesSlice';
import { NoChangesWarning, removeExtraFields } from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveImmigration = (t, dispatch, immigration, candidateId) => {
  if (candidateId !== undefined && immigration.changed) {
    const data = {
      candidateId,
      i140Submission: immigration.i140_submission,
      i140Approval: immigration.i140_approval,
      visaTypeId: immigration.visa_type_id,
      visaStatusId: immigration.visa_status_id,
      embassyAppointment: immigration.embassy_appointment,
      visaScreenAgencyId: immigration.visa_screen_agency_id,
      visaScreenApplicationStart: immigration.visa_screen_application_start,
      visaScreenApplicationExpiration: immigration.visa_screen_application_expiration,
      visaScreenIssue: immigration.visa_screen_issue,
      visaScreenExpiration: immigration.visa_screen_expiration,
      currentlyInUs: immigration.currently_in_us,
      currentImmigrationStatus: immigration.current_immigration_status,
      visaCategory: immigration.visa_category,
      asylumApproved: immigration.asylum_approved,
      issuedEad: immigration.issued_ead,
      issuedSsn: immigration.issued_ssn,
      priorityDate: immigration.priority_date,
      immigrationIssues: immigration.immigration_issues,
      immigrationIssuesDetail: immigration.immigration_issues_detail,
      legallyMarried: immigration.legally_married,
      children: immigration.children.map((c) => removeExtraFields(c)),
    };

    if (typeof immigration.id !== 'undefined') {
      data.id = immigration.id;
      dispatch(updateCandidateImmigration(data));
    } else {
      dispatch(createCandidateImmigration(data));
    }
  } else {
    NoChangesWarning(t);
  }
};
