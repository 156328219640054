import {
  createCandidateNursingBoard, deleteCandidateNursingBoard,
  updateCandidateNursingBoard,
} from 'reducers/candidatesSlice';
import {
  invalidRecords, MissingDataWarning, modifiedRecords, NoChangesWarning,
} from 'Util';

// eslint-disable-next-line import/prefer-default-export
export const saveNursingBoard = (t, dispatch, nursingBoards, candidateId) => {
  if (!invalidRecords(nursingBoards).length > 0) {
    const boards = nursingBoards.filter((item) => item.changed === true);
    if (boards.length > 0) {
      modifiedRecords(nursingBoards).forEach((e) => {
        const data = {
          candidateId,
          stateId: e.state_id,
          applicationBy: e.application_by,
          date: e.date,
          expiration: e.expiration,
          applicantId: e.applicant_id,
          username: e.username,
          password: e.password,
        };

        // eslint-disable-next-line no-underscore-dangle
        if (e._destroy === true) {
          dispatch(deleteCandidateNursingBoard({
            candidateId,
            id: e.id,
          }));
        } else if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateNursingBoard(data));
        } else {
          dispatch(createCandidateNursingBoard(data));
        }
      });
    } else {
      NoChangesWarning(t);
    }
  } else {
    MissingDataWarning(t);
  }
};
