import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  createCandidateAddress,
  createCandidateEmail,
  createCandidateEvaluation,
  createCandidateFingerprint,
  createCandidateLicense,
  createCandidatePhone,
  createCandidateProfile,
  deleteCandidateAddress,
  deleteCandidateEmail,
  deleteCandidateEvaluation,
  deleteCandidateFingerprint,
  deleteCandidateLicense,
  deleteCandidatePhone,
  setCreated,
  setCurrentView,
  setFailed,
  setProfileCreated,
  setRemoveData,
  setSaved,
  updateCandidateAddress,
  updateCandidateEmail,
  updateCandidateEvaluation,
  updateCandidateFingerprint,
  updateCandidateLicense,
  updateCandidatePhone,
  updateCandidateProfile,
} from 'reducers/candidatesSlice';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Util functions
import Swal from 'sweetalert2';
import {
  failedCandidateMessage,
  getCandidateUUID,
  MissingDataWarning,
  NoChangesWarning,
  savedCandidateMessage,
} from 'Util';

// Save functions
import { saveEducation } from './components/SaveEducation';
import { saveLanguage } from './components/SaveLanguage';
import { saveNCLEX } from './components/SaveNclex';
import { savePersonalInfo } from './components/SavePersonalInfo';
import { saveNursingBoard } from './components/SaveNursingBoard';
import { saveExperience } from './components/SaveExperience';
import { saveImmigration } from './components/SaveImmigration';

const prepareModifyData = (obj) => obj
  .map((e) => {
    if (typeof e.changed !== 'undefined' && e.changed === true) {
      return e;
    }
    return null;
  })
  .filter((x) => x !== null);

const prepareRemoveData = (obj) => obj
  .map((e) => {
    if (typeof e !== 'undefined') {
      return e;
    }
    return null;
  })
  .filter((x) => x !== 'undefined');

const selector = (state) => ({
  profile: state.candidate.profile,
  phones: state.candidate.phones,
  emails: state.candidate.emails,
  addresses: state.candidate.addresses,
  education: state.candidate.education,
  experience: state.candidate.experience,
  languages: state.candidate.languages,
  personal: state.candidate.personal,
  credentials: state.candidate.credentials,
  credentialEvaluations: state.candidate.credentialEvaluations,
  fingerprints: state.candidate.fingerprints,
  nursingBoards: state.candidate.nursingBoards,
  removeData: state.candidate.removeData,
  profileCreated: state.candidate.profileCreated,
  editing: state.candidate.editing,
  created: state.candidate.created,
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  currentSubView: state.candidate.currentSubView,
  nclex: state.candidate.nclex,
  nclexTests: state.candidate.nclexTests,
  nclexAssessmentTests: state.candidate.nclexAssessmentTests,
  pearsonRegistrations: state.candidate.pearsonRegistrations,
  immigration: state.candidate.immigration,
});

function SavePanel() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.save' });
  const {
    profile,
    phones,
    emails,
    addresses,
    education,
    experience,
    languages,
    personal,
    credentials,
    credentialEvaluations,
    fingerprints,
    nursingBoards,
    removeData,
    profileCreated,
    editing,
    created,
    saved,
    failed,
    errors,
    currentSubView,
    nclex,
    nclexTests,
    nclexAssessmentTests,
    pearsonRegistrations,
    immigration,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cUuid = getCandidateUUID();

  const saveContactInfo = (candidateId) => {
    // TODO refactor nested attributes saving
    const emailsChanged = emails.filter((item) => item.changed === true);
    const phonesChanged = phones.filter((item) => item.changed === true);
    const addressesChanged = addresses.filter((item) => item.changed === true);

    if (addressesChanged.length > 0 || removeData.addresses.length > 0
      || emailsChanged.length > 0 || removeData.emails.length > 0
      || phonesChanged.length > 0 || removeData.phones.length > 0) {
      prepareModifyData(emails).map((e) => {
        const data = {
          candidateId,
          email: e.email,
          isDefault: e.default,
        };
        if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateEmail(data));
        } else {
          dispatch(createCandidateEmail(data));
        }
        return true;
      });

      prepareRemoveData(removeData.emails).map((e) => {
        dispatch(
          deleteCandidateEmail({
            candidateId,
            id: e,
          }),
        );

        dispatch(
          setRemoveData({
            ...removeData,
            emails: removeData.emails.filter((i) => i !== e),
          }),
        );

        return true;
      });

      prepareModifyData(phones).map((e) => {
        const data = {
          candidateId,
          phone: e.phone,
          isDefault: e.default,
        };
        if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidatePhone(data));
        } else {
          dispatch(createCandidatePhone(data));
        }

        return true;
      });

      prepareRemoveData(removeData.phones).map((e) => {
        dispatch(
          deleteCandidatePhone({
            candidateId,
            id: e,
          }),
        );

        dispatch(
          setRemoveData({
            ...removeData,
            phones: removeData.phones.filter((i) => i !== e),
          }),
        );

        return true;
      });

      prepareModifyData(addresses).map((e) => {
        const data = {
          candidateId,
          address: e.address,
          cityId: e.city_id,
          stateId: e.state_id,
          countryId: e.country_id,
          postalCode: e.postal_code,
          addressTypeId: e.address_type_id,
          isDefault: e.default,
        };
        if (typeof e.id !== 'undefined') {
          data.id = e.id;
          dispatch(updateCandidateAddress(data));
        } else {
          dispatch(createCandidateAddress(data));
        }

        return true;
      });

      prepareRemoveData(removeData.addresses).map((e) => {
        dispatch(
          deleteCandidateAddress({
            candidateId,
            id: e,
          }),
        );

        dispatch(
          setRemoveData({
            ...removeData,
            addresses: removeData.addresses.filter((i) => i !== e),
          }),
        );

        return true;
      });

      return true;
    }
    return false;
  };

  const saveCandidateInfo = (candidateId) => {
    let isValid = (profile.last_name !== '' && profile.last_name !== undefined)
      && (profile.first_name !== '' && profile.first_name !== undefined)
      && (profile.country_id !== '' && profile.country_id !== undefined);

    const invalidEmails = emails.filter((i) => i.valid === false);
    const invalidPhones = phones.filter((i) => i.valid === false);
    const invalidAddresses = addresses.filter((i) => i.valid === false);

    if (invalidEmails.length > 0
      || invalidPhones.length > 0
      || invalidAddresses.length > 0) {
      isValid = false;
    }

    if (emails.length <= 0) {
      isValid = false;
    }

    if (phones.length <= 0) {
      isValid = false;
    }

    if (isValid) {
      const data = {
        firstName: profile.first_name,
        middleName: profile.middle_name,
        lastName: profile.last_name,
        preferredName: profile.preferred_name,
        dob: profile.dob,
        enable: profile.enable,
        languageId: profile.language_id,
        countryId: profile.country_id,
        residenceCountryId: profile.residence_country_id,
        citizenshipId: profile.citizenship_id,
        candidateStatusId: profile.candidate_status_id,
        ssn: profile.ssn,
        paymentPlan: profile.payment_plan,
        paymentPlanBilled: profile.payment_plan_billed,
        contactMethod: profile.contact_method,
        candidateEmails: emails,
        candidatePhones: phones,
        candidateAddresses: addresses,
      };

      if (editing === true) {
        data.uuid = candidateId;

        const changedContactInfo = saveContactInfo(candidateId);

        if (profile.changed) {
          dispatch(updateCandidateProfile(data));
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!changedContactInfo) {
            NoChangesWarning(t);
          }
        }
      } else {
        dispatch(createCandidateProfile(data));
      }
    } else {
      MissingDataWarning(t);
    }
  };

  const saveCredentials = (candidateId) => {
    const invalidCredentials = credentials.filter((i) => i.valid === false);

    let isValid = true;

    if (invalidCredentials.length > 0) {
      isValid = false;
    }

    if (isValid) {
      const creds = credentials.filter((item) => item.changed === true);
      if (creds.length > 0 || removeData.credentials.length > 0) {
        prepareModifyData(credentials).map((c) => {
          const data = {
            candidateId,
            countryId: c.country_id,
            stateId: c.state_id,
            expiration: c.expiration,
            noExpiration: c.no_expiration,
            licenseNumber: c.license_number,
            issue: c.issue,
          };
          if (typeof c.id !== 'undefined') {
            data.id = c.id;
            dispatch(updateCandidateLicense(data));
          } else {
            dispatch(createCandidateLicense(data));
          }

          return true;
        });

        prepareRemoveData(removeData.credentials).map((c) => {
          dispatch(
            deleteCandidateLicense({
              candidateId,
              id: c,
            }),
          );

          dispatch(
            setRemoveData({
              ...removeData,
              credentials: removeData.credentials.filter((i) => i !== c),
            }),
          );

          return true;
        });
      } else {
        NoChangesWarning(t);
      }
    } else {
      MissingDataWarning(t);
    }
  };

  const saveCredentialEvaluations = (candidateId) => {
    const invalidCredentialEvaluations = credentialEvaluations.filter((i) => i.valid === false);

    let isValid = true;

    if (invalidCredentialEvaluations.length > 0) {
      isValid = false;
    }

    if (isValid) {
      const evals = credentialEvaluations.filter((item) => item.changed === true);
      if (evals.length > 0 || removeData.credentialEvaluations.length > 0) {
        prepareModifyData(credentialEvaluations).map((e) => {
          const data = {
            candidateId,
            credentialEvaluatorId: e.credential_evaluator_id,
            processTypeId: e.process_type_id,
            startedDate: e.started_date,
            expirationDate: e.expiration_date,
            universityDocs: e.university_docs,
            licenseDocsSubmitted: e.license_docs_submitted,
            licenseDocsSubmissionMethodId: e.license_docs_submission_method_id,
            licenseDocsSubmissionDate: e.license_docs_submission_date,
            licenseDocsSubmissionTracking: e.license_docs_submission_tracking,
            licenseDocsSubmissionCarrier: e.license_docs_submission_carrier,
            translationSubmitted: e.translation_submitted,
            translationSubmissionDate: e.translation_submission_date,
            universityDocsVerified: e.university_docs_verified,
            universityDocsVerificationDate: e.university_docs_verification_date,
            licenseVerified: e.license_verified,
            licenseVerificationDate: e.license_verification_date,
            cesIssueDate: e.ces_issue_date,
          };
          if (typeof e.id !== 'undefined') {
            data.id = e.id;
            dispatch(updateCandidateEvaluation(data));
          } else {
            dispatch(createCandidateEvaluation(data));
          }

          return true;
        });

        prepareRemoveData(removeData.credentialEvaluations).map((c) => {
          dispatch(
            deleteCandidateEvaluation({
              candidateId,
              id: c,
            }),
          );

          dispatch(
            setRemoveData({
              ...removeData,
              credentialEvaluations: removeData.credentialEvaluations.filter((i) => i !== c),
            }),
          );

          return true;
        });
      } else {
        NoChangesWarning(t);
      }
    } else {
      MissingDataWarning(t);
    }
  };

  const saveFingerprint = (candidateId) => {
    const invalidFingerprints = fingerprints.filter((i) => i.valid === false);

    let isValid = true;

    if (invalidFingerprints.length > 0) {
      isValid = false;
    }

    if (isValid) {
      const evals = fingerprints.filter((item) => item.changed === true);
      if (evals.length > 0 || removeData.fingerprints.length > 0) {
        prepareModifyData(fingerprints).map((e) => {
          const data = {
            candidateId,
            stateId: e.state_id,
            submissionDate: e.submission_date,
            submissionCarrier: e.submission_carrier,
            submissionTracking: e.submission_tracking,
          };
          if (typeof e.id !== 'undefined') {
            data.id = e.id;
            dispatch(updateCandidateFingerprint(data));
          } else {
            dispatch(createCandidateFingerprint(data));
          }

          return true;
        });

        prepareRemoveData(removeData.fingerprints).map((c) => {
          dispatch(
            deleteCandidateFingerprint({
              candidateId,
              id: c,
            }),
          );

          dispatch(
            setRemoveData({
              ...removeData,
              fingerprints: removeData.fingerprints.filter((i) => i !== c),
            }),
          );

          return true;
        });
      } else {
        NoChangesWarning(t);
      }
    } else {
      MissingDataWarning(t);
    }
  };

  const handleSave = () => {
    if (currentSubView === 'basic-info') {
      if (cUuid === undefined) {
        saveCandidateInfo();
      } else {
        saveCandidateInfo(cUuid);
      }
    } else if (currentSubView !== 'basic-info' && cUuid !== undefined) {
      // eslint-disable-next-line default-case
      switch (currentSubView) {
        case 'education':
          saveEducation(t, dispatch, education, cUuid);
          break;
        case 'experience':
          saveExperience(t, dispatch, experience, cUuid);
          break;
        case 'language':
          saveLanguage(t, dispatch, languages, cUuid);
          break;
        case 'personal-info':
          savePersonalInfo(t, dispatch, personal, cUuid);
          break;
        case 'credentials':
          saveCredentials(cUuid);
          break;
        case 'credentials-evaluation':
          saveCredentialEvaluations(cUuid);
          break;
        case 'fingerprint':
          saveFingerprint(cUuid);
          break;
        case 'nursing-board':
          saveNursingBoard(t, dispatch, nursingBoards, cUuid);
          break;
        case 'nclex':
          saveNCLEX(
            t,
            dispatch,
            nclex,
            pearsonRegistrations,
            nclexAssessmentTests,
            nclexTests,
            cUuid,
          );
          break;
        case 'immigration':
          saveImmigration(t, dispatch, immigration, cUuid);
          break;
      }
    } else {
      Swal.fire({
        text: t('save-contact-info-first'),
        icon: 'error',
        confirmButtonText: t('close'),
      });
    }
  };

  if (profileCreated) {
    dispatch(setProfileCreated(false));
    dispatch(setCreated(true));
  }

  if (created) {
    dispatch(setCreated(false));

    Swal.fire({
      title: `${t('success')}!`,
      text: t('candidate-created-successfully'),
      icon: 'success',
      confirmButtonText: t('close'),
    }).then(() => {
      dispatch(setCurrentView('profile'));
      navigate(`/candidates/edit/${profile.uuid}`);
    });
  }
  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <SuiBox mt={5} mb={3}>
      <SuiBox display="flex" justifyContent="flex-end" mt={6}>
        <SuiBox mr={1}>
          <SuiButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleSave}
          >
            {t('save')}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default SavePanel;
