import Service from './service';

class CandidateService extends Service {
  // eslint-disable-next-line class-methods-use-this
  async getCandidates() {
    const response = await this.api
      .get(
        this.localizeURL('candidates'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesOutlook(statuses, enable) {
    const response = await this.api
      .get(
        this.localizeURL(enable !== null ? `candidates/outlook/${statuses}/${enable}` : `candidates/outlook/${statuses}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateProfile(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateProfile(
    firstName,
    middleName,
    lastName,
    preferredName,
    dob,
    enable,
    languageId,
    countryId,
    residenceCountryId,
    citizenshipId,
    candidateStatusId,
    ssn,
    preferredPaymentPlan,
    paymentPlanBilled,
    deferredPayment,
    contactMethod,
    deferredAmount,
    recruiterId,
    bsrUserId,
    candidateEmails,
    candidatePhones,
    candidateAddresses,
    recruitmentList,
  ) {
    const data = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      preferred_name: preferredName,
      dob,
      enable,
      language_id: languageId,
      country_id: countryId,
      residence_country_id: residenceCountryId,
      citizenship_id: citizenshipId,
      candidate_status_id: candidateStatusId,
      ssn,
      preferred_payment_plan: preferredPaymentPlan,
      payment_plan_billed: paymentPlanBilled,
      deferred_payment: deferredPayment,
      contact_method: contactMethod,
      deferred_amount: deferredAmount,
      recruiter_id: recruiterId,
      bsr_user_id: bsrUserId,
      candidate_emails_attributes: candidateEmails,
      candidate_phones_attributes: candidatePhones,
      candidate_addresses_attributes: candidateAddresses,
      recruitment_list: recruitmentList,
    };

    const response = await this.api
      .post(
        this.localizeURL('candidates'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateProfile(
    uuid,
    firstName,
    middleName,
    lastName,
    preferredName,
    dob,
    enable,
    languageId,
    countryId,
    residenceCountryId,
    citizenshipId,
    candidateStatusId,
    ssn,
    preferredPaymentPlan,
    paymentPlanBilled,
    deferredPayment,
    contactMethod,
    deferredAmount,
    recruiterId,
    bsrUserId,
    recruitmentList,
  ) {
    const data = {
      candidate: {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        preferred_name: preferredName,
        dob,
        enable,
        language_id: languageId,
        country_id: countryId,
        residence_country_id: residenceCountryId,
        citizenship_id: citizenshipId,
        candidate_status_id: candidateStatusId,
        ssn,
        preferred_payment_plan: preferredPaymentPlan,
        payment_plan_billed: paymentPlanBilled,
        deferred_payment: deferredPayment,
        contact_method: contactMethod,
        deferred_amount: deferredAmount,
        recruiter_id: recruiterId,
        bsr_user_id: bsrUserId,
        recruitment_list: recruitmentList,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${uuid}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateProfile(uuid) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${uuid}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateStatusHistory(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/status-history`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatePhonesInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/phones`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidatePhone(candidateId, phone, isDefault) {
    const data = {
      phone: {
        phone,
        default: isDefault,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/phones`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatePhone(id, candidateId, phone, isDefault) {
    const data = {
      phone: {
        phone,
        default: isDefault,
      },
    };
    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/phones/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidatePhone(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/phones/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async checkSimilarCandidatePhone(phone) {
    const data = {
      phone: {
        phone,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('candidates/phones/check'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateEmailsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/emails`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateEmail(candidateId, email, isDefault) {
    const data = {
      email: {
        email,
        default: isDefault,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/emails`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateEmail(id, candidateId, email, isDefault) {
    const data = {
      email: {
        email,
        default: isDefault,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/emails/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateEmail(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/emails/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async checkSimilarCandidateEmail(email) {
    const data = {
      email: {
        email,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('candidates/emails/check'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateAddressesInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/addresses`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateAddress(
    candidateId,
    address,
    cityId,
    stateId,
    countryId,
    postalCode,
    addressTypeId,
    isDefault,
  ) {
    const data = {
      address: {
        address,
        city_id: cityId,
        state_id: stateId,
        country_id: countryId,
        postal_code: postalCode,
        address_type_id: addressTypeId,
        default: isDefault,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/addresses`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateAddress(
    id,
    candidateId,
    address,
    cityId,
    stateId,
    countryId,
    postalCode,
    addressTypeId,
    isDefault,
  ) {
    const data = {
      address: {
        address,
        city_id: cityId,
        state_id: stateId,
        country_id: countryId,
        postal_code: postalCode,
        address_type_id: addressTypeId,
        default: isDefault,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/addresses/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateAddress(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/addresses/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateDegreesInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/degrees`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateDegree(
    candidateId,
    degree,
    educationLevelId,
    graduation,
    nameUnder,
    educationInstitutions,
  ) {
    const data = {
      degree: {
        degree,
        education_level_id: educationLevelId,
        graduation,
        name_under: nameUnder,
        education_institutions_attributes: educationInstitutions,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/degrees`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateDegree(
    id,
    candidateId,
    degree,
    educationLevelId,
    graduation,
    nameUnder,
    educationInstitutions,
  ) {
    const data = {
      degree: {
        degree,
        education_level_id: educationLevelId,
        graduation,
        name_under: nameUnder,
        education_institutions_attributes: educationInstitutions,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/degrees/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateDegree(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/degrees/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateExperiencesInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/experiences`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateExperience(
    candidateId,
    company,
    jobTitle,
    from,
    to,
    current,
    address,
    postalCode,
    cityId,
    stateId,
    countryId,
    clinicalExperiences,
    workType,
    supervisorName,
    supervisorTitle,
    supervisorEmail,
    supervisorPhone,
  ) {
    const data = {
      experience: {
        company,
        job_title: jobTitle,
        from,
        to,
        current,
        address,
        postal_code: postalCode,
        city_id: cityId,
        state_id: stateId,
        country_id: countryId,
        candidate_experience_clinical_experiences_attributes: clinicalExperiences,
        work_type: workType,
        supervisor_name: supervisorName,
        supervisor_title: supervisorTitle,
        supervisor_email: supervisorEmail,
        supervisor_phone: supervisorPhone,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/experiences`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateExperience(
    id,
    candidateId,
    company,
    jobTitle,
    from,
    to,
    current,
    address,
    postalCode,
    cityId,
    stateId,
    countryId,
    clinicalExperiences,
    workType,
    supervisorName,
    supervisorTitle,
    supervisorEmail,
    supervisorPhone,
  ) {
    const data = {
      experience: {
        company,
        job_title: jobTitle,
        from,
        to,
        current,
        address,
        postal_code: postalCode,
        city_id: cityId,
        state_id: stateId,
        country_id: countryId,
        candidate_experience_clinical_experiences_attributes: clinicalExperiences,
        work_type: workType,
        supervisor_name: supervisorName,
        supervisor_title: supervisorTitle,
        supervisor_email: supervisorEmail,
        supervisor_phone: supervisorPhone,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/experiences/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateExperience(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/experiences/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateResume(candidateId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/experiences/pdf`),
        { headers: this.getHeaders(), responseType: 'blob' },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateExperienceDocumentsInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/experience-documents`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateExperienceDocument(
    candidateId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/experience-documents`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateExperienceDocument(candidateId, documentId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/experience-documents/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadCandidateExperienceDocument(
    candidateId,
    documentId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/experience-documents/${documentId}/upload-files`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateDocumentsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/documents`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateDocument(
    id,
    uuid,
  ) {
    const data = {
      document: {
        document_id: id,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${uuid}/documents`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateDocument(
    id,
    candidateId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/documents/${id}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateDocument(
    id,
    candidateId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/documents/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateExpensesInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/expenses`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNotesInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/notes`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNoteInfo(uuid, id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/notes/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNote(
    candidateId,
    date,
    title,
    note,
    section,
  ) {
    const data = {
      note: {
        date,
        title,
        note,
        section,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/notes`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNote(
    id,
    candidateId,
    date,
    title,
    note,
    section,
  ) {
    const data = {
      note: {
        date,
        title,
        note,
        section,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/notes/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNote(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/notes/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateContactsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/contacts`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateContactInfo(uuid, id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/contacts/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateContact(candidateId, date, title, note, contactMethodId) {
    const data = {
      note: {
        date,
        title,
        note,
        contact_method_id: contactMethodId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/contacts`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateContact(
    id,
    candidateId,
    date,
    title,
    note,
    contactMethodId,
  ) {
    const data = {
      note: {
        date,
        title,
        note,
        contact_method_id: contactMethodId,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/contacts/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateContact(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/contacts/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateRemindersInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/reminders`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateReminderInfo(uuid, id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/reminders/${id}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateReminder(candidateId, date, title, note, remindOn, remindTo) {
    const data = {
      note: {
        date,
        title,
        note,
        remind_on: remindOn,
        remind_to: remindTo,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/reminders`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateReminder(
    id,
    candidateId,
    date,
    title,
    note,
    contactOutcomeId,
    contactMethodId,
  ) {
    const data = {
      note: {
        date,
        title,
        note,
        contact_outcome_id: contactOutcomeId,
        contact_method_id: contactMethodId,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/reminders/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateReminder(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/reminders/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNotesTimeline(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/notes_timeline`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateLanguageProficiencyInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/language-proficiencies`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLanguageProficiency(
    candidateId,
    candidateLanguageCourses,
    candidateLanguageTests,
    assessmentResult,
    assessmentDate,
    schoolName,
    languageProficiencyId,
  ) {
    const data = {
      proficiency: {
        assessment_result: assessmentResult,
        assessment_date: assessmentDate,
        candidate_language_courses_attributes: candidateLanguageCourses,
        candidate_language_tests_attributes: candidateLanguageTests,
        language_proficiency_id: languageProficiencyId,
        school_name: schoolName,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLanguageProficiency(
    id,
    candidateId,
    candidateLanguageCourses,
    candidateLanguageTests,
    assessmentResult,
    assessmentDate,
    schoolName,
    languageProficiencyId,
  ) {
    const data = {
      proficiency: {
        assessment_result: assessmentResult,
        assessment_date: assessmentDate,
        candidate_language_courses_attributes: candidateLanguageCourses,
        candidate_language_tests_attributes: candidateLanguageTests,
        language_proficiency_id: languageProficiencyId,
        school_name: schoolName,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLanguageTestDocument(
    proficiencyId,
    candidateId,
    testId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${proficiencyId}/tests/${testId}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLanguageTestDocument(
    proficiencyId,
    candidateId,
    testId,
    documentId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${proficiencyId}/tests/${testId}/remove-doc/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadCandidateLanguageTestDocument(
    proficiencyId,
    candidateId,
    testId,
    documentId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${proficiencyId}/tests/${testId}/upload-files/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLanguageProficiency(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateLanguageCoursesInfo(candidateId, languageProficiencyId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/courses`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLanguageCourse(
    candidateId,
    languageProficiencyId,
    schoolName,
    bsr,
    programName,
    started,
    finished,
    current,
  ) {
    const data = {
      course: {
        school_name: schoolName,
        bsr,
        program_name: programName,
        started,
        finished,
        current,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/courses`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLanguageCourse(
    id,
    candidateId,
    languageProficiencyId,
    schoolName,
    bsr,
    programName,
    started,
    finished,
    current,
  ) {
    const data = {
      course: {
        school_name: schoolName,
        bsr,
        program_name: programName,
        started,
        finished,
        current,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/courses/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLanguageCourse(id, candidateId, languageProficiencyId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/courses/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateLanguageCoursesProgress(candidateId, languageProficiencyId, courseId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies`
          + `/${languageProficiencyId}/courses/${courseId}/progress`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLanguageCourseProgress(
    candidateId,
    languageProficiencyId,
    courseId,
    level,
    date,
    progress,
    enrollmentDate,
    lastAccess,
  ) {
    const data = {
      progress: {
        candidate_language_course_id: courseId,
        level,
        date,
        progress,
        enrollment_date: enrollmentDate,
        last_access: lastAccess,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies`
          + `/${languageProficiencyId}/courses/${courseId}/progress`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLanguageCourseProgress(
    id,
    candidateId,
    languageProficiencyId,
    courseId,
    level,
    date,
    progress,
    enrollmentDate,
    lastAccess,
  ) {
    const data = {
      progress: {
        candidate_language_course_id: courseId,
        level,
        date,
        progress,
        enrollment_date: enrollmentDate,
        last_access: lastAccess,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies`
          + `/${languageProficiencyId}/courses/${courseId}/progress/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLanguageCourseProgress(
    id,
    candidateId,
    languageProficiencyId,
    courseId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies`
          + `/${languageProficiencyId}/courses/${courseId}/progress/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateLanguageTestsInfo(candidateId, languageProficiencyId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/tests`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLanguageTest(
    candidateId,
    languageProficiencyId,
    taken,
    schedule,
    reading,
    writing,
    listening,
    speaking,
    overall,
    sentToBon,
    languageProficiencyTestId,
  ) {
    const data = {
      test: {
        taken,
        schedule,
        reading,
        writing,
        listening,
        speaking,
        overall,
        sent_to_bon: sentToBon,
        language_proficiency_test_id: languageProficiencyTestId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/tests`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLanguageTest(
    id,
    candidateId,
    languageProficiencyId,
    taken,
    schedule,
    reading,
    writing,
    listening,
    speaking,
    overall,
    sentToBon,
    languageProficiencyTestId,
  ) {
    const data = {
      test: {
        taken,
        schedule,
        reading,
        writing,
        listening,
        speaking,
        overall,
        sent_to_bon: sentToBon,
        language_proficiency_test_id: languageProficiencyTestId,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/tests/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLanguageTest(id, candidateId, languageProficiencyId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/language-proficiencies/${languageProficiencyId}/tests/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatePersonal(candidateId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/personal`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidatePersonal(
    candidateId,
    weight,
    height,
    hairColor,
    eyeColor,
    raceId,
    willingCovidVaccine,
    willingInfluenzaVaccine,
    immigrationIssues,
    immigrationIssuesDetail,
    countryId,
    passportDateIssue,
    passportDateExpiration,
    passportNumber,
    currentlyInUs,
    currentImmigrationStatus,
    visaCategory,
    asylumApproved,
    issuedEad,
    issuedSsn,
    legallyMarried,
  ) {
    const data = {
      personal: {
        weight,
        height,
        hair_color: hairColor,
        eye_color: eyeColor,
        race_id: raceId,
        willing_covid_vaccine: willingCovidVaccine,
        willing_influenza_vaccine: willingInfluenzaVaccine,
        immigration_issues: immigrationIssues,
        immigration_issues_detail: immigrationIssuesDetail,
        country_id: countryId,
        passport_date_issue: passportDateIssue,
        passport_date_expiration: passportDateExpiration,
        passport_number: passportNumber,
        currently_in_us: currentlyInUs,
        current_immigration_status: currentImmigrationStatus,
        visa_category: visaCategory,
        asylum_approved: asylumApproved,
        issued_ead: issuedEad,
        issued_ssn: issuedSsn,
        legally_married: legallyMarried,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/personal`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatePersonal(
    id,
    candidateId,
    weight,
    height,
    hairColor,
    eyeColor,
    raceId,
    willingCovidVaccine,
    willingInfluenzaVaccine,
    immigrationIssues,
    immigrationIssuesDetail,
    countryId,
    passportDateIssue,
    passportDateExpiration,
    passportNumber,
    currentlyInUs,
    currentImmigrationStatus,
    visaCategory,
    asylumApproved,
    issuedEad,
    issuedSsn,
    legallyMarried,
  ) {
    const data = {
      personal: {
        weight,
        height,
        hair_color: hairColor,
        eye_color: eyeColor,
        race_id: raceId,
        willing_covid_vaccine: willingCovidVaccine,
        willing_influenza_vaccine: willingInfluenzaVaccine,
        immigration_issues: immigrationIssues,
        immigration_issues_detail: immigrationIssuesDetail,
        country_id: countryId,
        passport_date_issue: passportDateIssue,
        passport_date_expiration: passportDateExpiration,
        passport_number: passportNumber,
        currently_in_us: currentlyInUs,
        current_immigration_status: currentImmigrationStatus,
        visa_category: visaCategory,
        asylum_approved: asylumApproved,
        issued_ead: issuedEad,
        issued_ssn: issuedSsn,
        legally_married: legallyMarried,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/personal/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatePersonalSignature(
    id,
    candidateId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('signature', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/personal/${id}/upload-signature`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatePersonalPassport(
    id,
    candidateId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('passport', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/personal/${id}/upload-passport`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateImmigration(candidateId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/immigration`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateImmigration(
    candidateId,
    i140Submission,
    i140Approval,
    visaTypeId,
    visaStatusId,
    embassyAppointment,
    visaScreenAgencyId,
    visaScreenStart,
    visaScreenIssue,
    visaScreenExpiration,
    currentlyInUs,
    currentImmigrationStatus,
    visaCategory,
    asylumApproved,
    issuedEad,
    issuedSsn,
    priorityDate,
    immigrationIssues,
    immigrationIssuesDetail,
    legallyMarried,
    children,
  ) {
    const data = {
      immigration: {
        i140_submission: i140Submission,
        i140_approval: i140Approval,
        visa_type_id: visaTypeId,
        visa_status_id: visaStatusId,
        embassy_appointment: embassyAppointment,
        visa_screen_agency_id: visaScreenAgencyId,
        visa_screen_start: visaScreenStart,
        visa_screen_issue: visaScreenIssue,
        visa_screen_expiration: visaScreenExpiration,
        currently_in_us: currentlyInUs,
        current_immigration_status: currentImmigrationStatus,
        visa_category: visaCategory,
        asylum_approved: asylumApproved,
        issued_ead: issuedEad,
        issued_ssn: issuedSsn,
        priority_date: priorityDate,
        immigration_issues: immigrationIssues,
        immigration_issues_detail: immigrationIssuesDetail,
        legally_married: legallyMarried,
        candidate_children_attributes: children,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/immigration`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateImmigration(
    id,
    candidateId,
    i140Submission,
    i140Approval,
    visaTypeId,
    visaStatusId,
    embassyAppointment,
    visaScreenAgencyId,
    visaScreenStart,
    visaScreenIssue,
    visaScreenExpiration,
    currentlyInUs,
    currentImmigrationStatus,
    visaCategory,
    asylumApproved,
    issuedEad,
    issuedSsn,
    priorityDate,
    immigrationIssues,
    immigrationIssuesDetail,
    legallyMarried,
    children,
  ) {
    const data = {
      immigration: {
        i140_submission: i140Submission,
        i140_approval: i140Approval,
        visa_type_id: visaTypeId,
        visa_status_id: visaStatusId,
        embassy_appointment: embassyAppointment,
        visa_screen_agency_id: visaScreenAgencyId,
        visa_screen_start: visaScreenStart,
        visa_screen_issue: visaScreenIssue,
        visa_screen_expiration: visaScreenExpiration,
        currently_in_us: currentlyInUs,
        current_immigration_status: currentImmigrationStatus,
        visa_category: visaCategory,
        asylum_approved: asylumApproved,
        issued_ead: issuedEad,
        issued_ssn: issuedSsn,
        priority_date: priorityDate,
        immigration_issues: immigrationIssues,
        immigration_issues_detail: immigrationIssuesDetail,
        legally_married: legallyMarried,
        candidate_children_attributes: children,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/immigration/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateImmigrationDocument(
    candidateId,
    immigrationId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/immigration/${immigrationId}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateImmigrationDocument(
    candidateId,
    immigrationId,
    documentId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/immigration/${immigrationId}/upload-files/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateImmigrationDocument(
    candidateId,
    immigrationId,
    documentId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/immigration/${immigrationId}/remove-doc/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateUpcomingEvents(candidateId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/upcoming-events`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateLicensesInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/licenses`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateLicense(
    candidateId,
    countryId,
    stateId,
    expiration,
    noExpiration,
    licenseNumber,
    issue,
  ) {
    const data = {
      license: {
        country_id: countryId,
        state_id: stateId,
        expiration,
        no_expiration: noExpiration,
        license_number: licenseNumber,
        issue,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/licenses`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLicense(
    id,
    candidateId,
    countryId,
    stateId,
    expiration,
    noExpiration,
    licenseNumber,
    issue,
  ) {
    const data = {
      license: {
        country_id: countryId,
        state_id: stateId,
        expiration,
        no_expiration: noExpiration,
        license_number: licenseNumber,
        issue,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/licenses/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateLicense(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/licenses/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateLicenseDocument(
    id,
    candidateId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/licenses/${id}/upload-files`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateEvaluationsInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/credential-evaluations`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateEvaluation(
    candidateId,
    credentialEvaluatorId,
    processTypeId,
    startedDate,
    expirationDate,
    universityDocs,
    licenseDocsSubmitted,
    licenseDocsSubmissionMethodId,
    licenseDocsSubmissionDate,
    licenseDocsSubmissionTracking,
    licenseDocsSubmissionCarrier,
    translationSubmitted,
    translationSubmissionDate,
    universityDocsVerified,
    universityDocsVerificationDate,
    licenseVerified,
    licenseVerificationDate,
    cesIssueDate,
  ) {
    const data = {
      evaluation: {
        credential_evaluator_id: credentialEvaluatorId,
        process_type_id: processTypeId,
        started_date: startedDate,
        expiration_date: expirationDate,
        evaluation_university_documents_attributes: universityDocs,
        license_docs_submitted: licenseDocsSubmitted,
        license_docs_submission_method_id: licenseDocsSubmissionMethodId,
        license_docs_submission_date: licenseDocsSubmissionDate,
        license_docs_submission_tracking: licenseDocsSubmissionTracking,
        license_docs_submission_carrier: licenseDocsSubmissionCarrier,
        translation_submitted: translationSubmitted,
        translation_submission_date: translationSubmissionDate,
        university_docs_verified: universityDocsVerified,
        university_docs_verification_date: universityDocsVerificationDate,
        license_verified: licenseVerified,
        license_verification_date: licenseVerificationDate,
        ces_issue_date: cesIssueDate,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateEvaluation(
    id,
    candidateId,
    credentialEvaluatorId,
    processTypeId,
    startedDate,
    expirationDate,
    universityDocs,
    licenseDocsSubmitted,
    licenseDocsSubmissionMethodId,
    licenseDocsSubmissionDate,
    licenseDocsSubmissionTracking,
    licenseDocsSubmissionCarrier,
    translationSubmitted,
    translationSubmissionDate,
    universityDocsVerified,
    universityDocsVerificationDate,
    licenseVerified,
    licenseVerificationDate,
    cesIssueDate,
  ) {
    const data = {
      evaluation: {
        credential_evaluator_id: credentialEvaluatorId,
        process_type_id: processTypeId,
        started_date: startedDate,
        expiration_date: expirationDate,
        evaluation_university_documents_attributes: universityDocs,
        license_docs_submitted: licenseDocsSubmitted,
        license_docs_submission_method_id: licenseDocsSubmissionMethodId,
        license_docs_submission_date: licenseDocsSubmissionDate,
        license_docs_submission_tracking: licenseDocsSubmissionTracking,
        license_docs_submission_carrier: licenseDocsSubmissionCarrier,
        translation_submitted: translationSubmitted,
        translation_submission_date: translationSubmissionDate,
        university_docs_verified: universityDocsVerified,
        university_docs_verification_date: universityDocsVerificationDate,
        license_verified: licenseVerified,
        license_verification_date: licenseVerificationDate,
        ces_issue_date: cesIssueDate,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateEvaluation(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateEvaluationUniversityDocument(
    id,
    candidateId,
    typeId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations/${id}/assign-doc/${typeId}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadCandidateEvaluationUniversityDocument(
    id,
    candidateId,
    typeId,
    documentId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations/${id}/upload-files/${typeId}/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateEvaluationUniversityDocument(id, candidateId, typeId, documentId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/credential-evaluations/${id}/remove-file/${typeId}/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateAgreementsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/agreements`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateAgreement(
    id,
    uuid,
    parameters,
  ) {
    const data = {
      agreement: {
        agreement_id: id,
        parameters,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${uuid}/agreements`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateAgreement(
    id,
    candidateId,
    signed,
    signedOn,
    assignedOn,
    expiresOn,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      agreement: {
        signed,
        signed_on: signedOn,
        assigned_on: assignedOn,
        expires_on: expiresOn,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/agreements/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async signCandidateAgreement(
    id,
    candidateId,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      agreement: {
        agreement_id: id,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/agreements/${id}/sign`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateAgreementDocument(
    id,
    candidateId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/agreements/${id}/upload-files`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateAgreement(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/agreements/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateFingerprintsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/fingerprints`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateFingerprint(
    candidateId,
    stateId,
    submissionDate,
    submissionCarrier,
    submissionTracking,
  ) {
    const data = {
      fingerprint: {
        state_id: stateId,
        submission_date: submissionDate,
        submission_carrier: submissionCarrier,
        submission_tracking: submissionTracking,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/fingerprints`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateFingerprint(
    id,
    candidateId,
    stateId,
    submissionDate,
    submissionCarrier,
    submissionTracking,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      fingerprint: {
        state_id: stateId,
        submission_date: submissionDate,
        submission_carrier: submissionCarrier,
        submission_tracking: submissionTracking,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/fingerprints/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateFingerprint(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/fingerprints/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateFingerprintDocs(
    id,
    candidateId,
    templateId,
    parameters,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      fingerprint: {
        template_id: templateId,
        parameters,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/fingerprints/${id}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadCandidateFingerprintDocs(
    id,
    candidateId,
    documentId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/fingerprints/${id}/upload-file/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateFingerprintDocs(
    id,
    candidateId,
    documentId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/fingerprints/${id}/remove-file/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNursingBoardsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/nursing-boards`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNursingBoard(
    candidateId,
    stateId,
    applicationBy,
    date,
    expiration,
    applicantId,
    username,
    password,
  ) {
    const data = {
      board: {
        state_id: stateId,
        application_by: applicationBy,
        date,
        expiration,
        applicant_id: applicantId,
        username,
        password,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nursing-boards`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNursingBoard(
    id,
    candidateId,
    applicationBy,
    stateId,
    date,
    expiration,
    applicantId,
    username,
    password,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      board: {
        state_id: stateId,
        application_by: applicationBy,
        date,
        expiration,
        applicant_id: applicantId,
        username,
        password,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nursing-boards/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNursingBoard(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nursing-boards/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNursingBoardDocument(
    id,
    candidateId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nursing-boards/${id}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadCandidateNursingBoardDocument(
    id,
    candidateId,
    documentId,
    file,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', file);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nursing-boards/${id}/upload-files/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNursingBoardDocument(id, candidateId, documentId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nursing-boards/${id}/remove-file/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateInterviewsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/interviews`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateInterview(
    candidateId,
    preparationEmailSentOn,
    preparationInterviewSchedule,
    interviewSchedule,
    offerStatus,
    positionListingId,
    offerReceived,
    offerReceivedDate,
  ) {
    const data = {
      interview: {
        preparation_email_sent_on: preparationEmailSentOn,
        preparation_interview_schedule: preparationInterviewSchedule,
        interview_schedule: interviewSchedule,
        offer_status: offerStatus,
        position_listing_id: positionListingId,
        offer_received: offerReceived,
        offer_received_date: offerReceivedDate,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/interviews`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateInterview(
    id,
    candidateId,
    preparationEmailSentOn,
    preparationInterviewSchedule,
    interviewSchedule,
    offerStatus,
    positionListingId,
    offerReceived,
    offerReceivedDate,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      interview: {
        preparation_email_sent_on: preparationEmailSentOn,
        preparation_interview_schedule: preparationInterviewSchedule,
        interview_schedule: interviewSchedule,
        offer_status: offerStatus,
        position_listing_id: positionListingId,
        offer_received: offerReceived,
        offer_received_date: offerReceivedDate,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/interviews/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateInterview(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/interviews/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateUserCandidate(
    candidateId,
    uuid,
  ) {
    // eslint-disable-next-line no-undef
    const data = {
      candidate: {
        uuid,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/assign-user/`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatePreferences(candidateId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${candidateId}/preferences`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidatePreferences(
    candidateId,
    emailNotifications,
    languageId,
    timezoneId,
  ) {
    const data = {
      preferences: {
        email_notifications: emailNotifications,
        language_id: languageId,
        timezone_id: timezoneId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/preferences`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatePreferences(
    id,
    candidateId,
    emailNotifications,
    languageId,
    timezoneId,
  ) {
    const data = {
      preferences: {
        email_notifications: emailNotifications,
        language_id: languageId,
        timezone_id: timezoneId,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/preferences/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNclexsInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/nclexs`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNclex(
    candidateId,
    archerRegistrationDate,
    archerExpirationDate,
    archerUsername,
    archerPassword,
    saundersRegistrationDate,
    saundersExpirationDate,
    saundersUsername,
    saundersPassword,
    expectedDate,
  ) {
    const data = {
      nclex: {
        archer_registration_date: archerRegistrationDate,
        archer_expiration_date: archerExpirationDate,
        archer_username: archerUsername,
        archer_password: archerPassword,
        saunders_registration_date: saundersRegistrationDate,
        saunders_expiration_date: saundersExpirationDate,
        saunders_username: saundersUsername,
        saunders_password: saundersPassword,
        expected_date: expectedDate,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nclexs`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNclex(
    id,
    candidateId,
    archerRegistrationDate,
    archerExpirationDate,
    archerUsername,
    archerPassword,
    saundersRegistrationDate,
    saundersExpirationDate,
    saundersUsername,
    saundersPassword,
    expectedDate,
  ) {
    const data = {
      nclex: {
        archer_registration_date: archerRegistrationDate,
        archer_expiration_date: archerExpirationDate,
        archer_username: archerUsername,
        archer_password: archerPassword,
        saunders_registration_date: saundersRegistrationDate,
        saunders_expiration_date: saundersExpirationDate,
        saunders_username: saundersUsername,
        saunders_password: saundersPassword,
        expected_date: expectedDate,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nclexs/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatePearsonInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/pearson`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this,max-len
  async createCandidatePearson(candidateId, user, password, registrationDate, ncsbnId, paymentDate) {
    const data = {
      pearson: {
        user,
        password,
        registration_date: registrationDate,
        ncsbn_id: ncsbnId,
        payment_date: paymentDate,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/pearson`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this,max-len
  async updateCandidatePearson(id, candidateId, user, password, registrationDate, ncsbnId, paymentDate) {
    const data = {
      pearson: {
        user,
        password,
        registration_date: registrationDate,
        ncsbn_id: ncsbnId,
        payment_date: paymentDate,
      },
    };
    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/pearson/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidatePearson(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/pearson/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async importCandidates(
    candidates,
    advisorId,
  ) {
    const data = {
      import: {
        data: candidates,
        advisor_id: advisorId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('candidates/import'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async importFinancialCandidate(
    candidateId,
    importData,
  ) {
    const data = {
      import: {
        data: importData,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/import-financial`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNclex(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nclexs/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNclexTestsInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/nclex-tests`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNclexTest(
    candidateId,
    schedule,
    nclexResultId,
    countryId,
    attNumber,
    attReceived,
    attExpiration,
  ) {
    const data = {
      test: {
        schedule,
        nclex_result_id: nclexResultId,
        country_id: countryId,
        att_number: attNumber,
        att_received: attReceived,
        att_expiration: attExpiration,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nclex-tests`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNclexTest(
    id,
    candidateId,
    schedule,
    nclexResultId,
    countryId,
    attNumber,
    attReceived,
    attExpiration,
  ) {
    const data = {
      test: {
        nclex_result_id: nclexResultId,
        country_id: countryId,
        att_number: attNumber,
        att_received: attReceived,
        att_expiration: attExpiration,
        schedule,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nclex-tests/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNclexTest(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nclex-tests/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNclexTestDocument(
    candidateId,
    testId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nclex-tests/${testId}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNclexTestDocument(
    candidateId,
    testId,
    documentId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nclex-tests/${testId}/upload-files/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNclexTestDocument(
    candidateId,
    testId,
    documentId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nclex-tests/${testId}/remove-doc/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateNclexAssessmentTestsInfo(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/nclex-assessment-tests`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateNclexAssessmentTest(
    candidateId,
    date,
    score,
    result,
    questions,
    challengingTopicId,
    nclexReadinessId,
    typeTest,
  ) {
    const data = {
      assessment: {
        date,
        score,
        result,
        questions,
        challenging_topic_id: challengingTopicId,
        nclex_readiness_id: nclexReadinessId,
        type_test: typeTest,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/nclex-assessment-tests`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateNclexAssessmentTest(
    id,
    candidateId,
    date,
    score,
    result,
    questions,
    challengingTopicId,
    nclexReadinessId,
    typeTest,
  ) {
    const data = {
      assessment: {
        date,
        score,
        result,
        questions,
        challenging_topic_id: challengingTopicId,
        nclex_readiness_id: nclexReadinessId,
        type_test: typeTest,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/nclex-assessment-tests/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateNclexAssessmentTest(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/nclex-assessment-tests/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateTemplateVariables(id) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${id}/template-values`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async assignAdvisors(id, advisors) {
    const data = {
      user: {
        advisors,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${id}/assign-advisors`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateAdvisors(id, advisors) {
    const data = {
      user: {
        advisors,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${id}/update-advisors`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async removeAdvisors(id, advisors) {
    const data = {
      user: {
        advisors,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${id}/remove-advisors`),
        data,
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateMeetingsInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/meetings`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateMeetingsByTypeInfo(uuid, meetingTypeId) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/meetings/type/${meetingTypeId}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateMeeting(
    candidateId,
    meetingTypeId,
    meetingOutcomeId,
    subject,
    starts,
    ends,
    notes,
    meetingAttendees,
    metadata,
  ) {
    const data = {
      meeting: {
        meeting_type_id: meetingTypeId,
        meeting_outcome_id: meetingOutcomeId,
        subject,
        starts,
        ends,
        notes,
        meeting_attendees_attributes: meetingAttendees,
        metadata,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/meetings`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateMeeting(
    id,
    candidateId,
    meetingTypeId,
    meetingOutcomeId,
    subject,
    starts,
    ends,
    notes,
    meetingAttendees,
    metadata,
  ) {
    const data = {
      meeting: {
        meeting_type_id: meetingTypeId,
        meeting_outcome_id: meetingOutcomeId,
        subject,
        starts,
        ends,
        notes,
        meeting_attendees_attributes: meetingAttendees,
        metadata,
      },
    };

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/meetings/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateMeeting(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/meetings/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async preValidateCandidateInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/pre-validate/`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async validateCandidateInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/validate/`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidatesLanguageProgress() {
    const response = await this.api
      .get(
        this.localizeURL('candidates/progress/'),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidatesLanguageProgress(
    progress,
  ) {
    const data = {
      progress,
    };

    const response = await this.api
      .put(
        this.localizeURL('candidates/progress/'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async checkSimilarCandidates(
    firstName,
    lastName,
  ) {
    const data = {
      candidate: {
        first_name: firstName,
        last_name: lastName,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL('candidates/check/'),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchCandidateActivity(uuid, page) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/activity?page=${page}`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async getCandidateRecruitmentInfo(uuid) {
    const response = await this.api
      .get(
        this.localizeURL(`candidates/${uuid}/recruitments`),
        { headers: this.getHeaders() },
      );
    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this,max-len
  async createCandidateRecruitment(
    candidateId,
    positionListingId,
    recruitmentStageId,
    startDate,
    clientInfoSentOn,
    sentToClientOn,
    preparationEmail,
    preparationInterview,
    interviewDate,
    offerReceived,
    offerReceivedDate,
    offerStatusId,
    offerAcceptanceDate,
  ) {
    const data = {
      recruitment: {
        position_listing_id: positionListingId,
        recruitment_stage_id: recruitmentStageId,
        start_date: startDate,
        client_info_sent_on: clientInfoSentOn,
        sent_to_client_on: sentToClientOn,
        preparation_email: preparationEmail,
        preparation_interview: preparationInterview,
        interview_date: interviewDate,
        offer_received: offerReceived,
        offer_received_date: offerReceivedDate,
        offer_status_id: offerStatusId,
        offer_acceptance_date: offerAcceptanceDate,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/recruitments`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this,max-len
  async updateCandidateRecruitment(
    id,
    candidateId,
    positionListingId,
    recruitmentStageId,
    startDate,
    clientInfoSentOn,
    sentToClientOn,
    preparationEmail,
    preparationInterview,
    interviewDate,
    offerReceived,
    offerReceivedDate,
    offerStatusId,
    offerAcceptanceDate,
    offerRejectedBy,
    rejectedOn,
    rejectedInStage,
    startingDate,
    flightDate,
    attorneyContactId,
  ) {
    const data = {
      recruitment: {
        position_listing_id: positionListingId,
        recruitment_stage_id: recruitmentStageId,
        start_date: startDate,
        client_info_sent_on: clientInfoSentOn,
        sent_to_client_on: sentToClientOn,
        preparation_email: preparationEmail,
        preparation_interview: preparationInterview,
        interview_date: interviewDate,
        offer_received: offerReceived,
        offer_received_date: offerReceivedDate,
        offer_status_id: offerStatusId,
        offer_acceptance_date: offerAcceptanceDate,
        offer_rejected_by: offerRejectedBy,
        rejected_on: rejectedOn,
        rejected_in_stage: rejectedInStage,
        starting_date: startingDate,
        flight_date: flightDate,
        attorney_contact_id: attorneyContactId,
      },
    };
    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/recruitments/${id}`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateRecruitment(id, candidateId) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/recruitments/${id}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async createCandidateRecruitmentDocument(
    candidateId,
    recruitmentId,
    documentId,
  ) {
    const data = {
      document: {
        document_id: documentId,
      },
    };

    const response = await this.api
      .post(
        this.localizeURL(`candidates/${candidateId}/recruitments/${recruitmentId}/assign-doc`),
        data,
        { headers: this.getHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCandidateRecruitmentDocument(
    candidateId,
    recruitmentId,
    documentId,
    files,
  ) {
    // eslint-disable-next-line no-undef
    const data = new FormData();
    data.append('file', files);

    const response = await this.api
      .put(
        this.localizeURL(`candidates/${candidateId}/recruitments/${recruitmentId}/upload-files/${documentId}`),
        data,
        { headers: this.getFormHeaders() },
      );

    return response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteCandidateRecruitmentDocument(
    candidateId,
    recruitmentId,
    documentId,
  ) {
    const response = await this.api
      .delete(
        this.localizeURL(`candidates/${candidateId}/recruitments/${recruitmentId}/remove-doc/${documentId}`),
        { headers: this.getHeaders() },
      );

    return response.data;
  }
}

export default new CandidateService();
