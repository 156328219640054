import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import {
  assignCandidateRecruitmentDocument,
  deleteCandidateRecruitment,
  deleteCandidateRecruitmentDocument,
  fetchCandidateRecruitment,
  setFailed,
  setSaved,
  updateCandidateRecruitment,
  uploadCandidateRecruitmentDocument,
} from 'reducers/candidatesSlice';
import { fetchDocuments } from 'reducers/documentsSlice';
import { fetchAttorney } from 'reducers/attorneySlice';

// @material-ui core components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';

// Components
import OutlineTable from 'layouts/components/Cards/PositionCards/components/OutlineTable';
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';
import ApplicationPositionCard
  from 'layouts/components/Cards/PositionCards/ApplicationPositionCard';
import DefaultDocumentCard from 'layouts/components/Cards/DocumentCards/DefaultDocumentCard';
import DefaultModal from 'layouts/components/Modal/DefaultModal';

// Functions
import {
  defaultValue,
  failedCandidateMessage,
  findSelectValue,
  getCandidateUUID,
  savedCandidateMessage,
} from 'Util';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';

const selector = (state) => ({
  saved: state.candidate.saved,
  failed: state.candidate.failed,
  errors: state.candidate.errors,
  recruitments: state.candidate.recruitments,
  documents: state.document.documents,
  attorneyInfo: state.attorney.attorneyInfo,
});

function JobApplications() {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.job-applications' });
  const {
    saved,
    failed,
    errors,
    recruitments,
    documents,
    attorneyInfo,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  const [openModal, setOpenModal] = useState(false);
  const [position, setPosition] = useState({});
  const [recruitment, setRecruitment] = useState({});
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [availDocs, setAvailDocs] = useState([]);
  const [assignedDocs, setAssignedDocs] = useState([]);
  const [attorneyContacts, setAttorneyContacts] = useState([]);

  const stages = [
    {
      value: 1,
      label: t('sourced'),
    },
    {
      value: 2,
      label: t('screened'),
    },
    {
      value: 3,
      label: t('interview'),
    },
    {
      value: 4,
      label: t('offered'),
    },
    {
      value: 5,
      label: t('hired'),
    },
    {
      value: 6,
      label: t('rejected'),
    },
  ];

  const offerReceived = [
    {
      value: false,
      label: t('no', { keyPrefix: 'common' }),
    },
    {
      value: true,
      label: t('yes', { keyPrefix: 'common' }),
    },
  ];

  const offerStatuses = [
    {
      value: 1,
      label: t('pending'),
    },
    {
      value: 2,
      label: t('rejected'),
    },
    {
      value: 3,
      label: t('accepted'),
    },
  ];

  const rejectBy = [
    {
      value: 1,
      label: t('candidate'),
    },
    {
      value: 2,
      label: t('client'),
    },
  ];

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchCandidateRecruitment({ uuid }));
  }, [dispatch, uuid]);

  useEffect(() => {
    if (recruitment.documents !== undefined) {
      setAvailDocs(documents
        .filter((doc) => doc.document_type_id === 13)
        .filter((ad) => !recruitment.documents.some((cd) => ad.id === cd.document.id)));
      setAssignedDocs(recruitment.documents);
    }
  }, [documents, recruitment]);

  useEffect(() => {
    if (recruitment.id !== undefined) {
      setRecruitment(recruitments
        .find((obj) => obj.id === recruitment.id));
    }
  }, [recruitment.id, recruitments]);

  useEffect(() => {
    if (attorneyInfo !== undefined && attorneyInfo.contacts !== undefined) {
      setAttorneyContacts(
        attorneyInfo.contacts
          .map((c) => ({ value: c.id, label: `${c.name} - ${c.position}` })),
      );
    }
  }, [attorneyInfo]);

  const setValue = (key, value) => {
    if (key === 'recruitment_stage_id' && value !== 4) {
      setRecruitment({
        ...recruitment,
        [key]: value,
        offer_status_id: recruitment.offer_status_id === 2 ? null : recruitment.offer_status_id,
        changed: true,
      });
    } else if (key === 'offer_status_id' && value === 2) {
      setRecruitment({
        ...recruitment,
        [key]: value,
        rejected_in_stage: recruitment.recruitment_stage_id,
        changed: true,
      });
    } else {
      setRecruitment({
        ...recruitment,
        [key]: value,
        changed: true,
      });
    }
  };

  const handleOpenModal = (e, id) => {
    e.preventDefault();
    const pos = recruitments.find((p) => p.id === id);
    setPosition(pos.position_listing);
    setRecruitment(pos);
    if (pos.position_listing.client_attorney !== undefined) {
      dispatch(fetchAttorney({ id: pos.position_listing.client_attorney }));
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setRecruitment({});
    setOpenModal(false);
  };

  const handleSave = () => {
    if (recruitment.id !== undefined) {
      dispatch(updateCandidateRecruitment({
        id: recruitment.id,
        candidateId: uuid,
        positionListingId: recruitment.position_listing_id,
        recruitmentStageId: recruitment.offer_status_id === 2
          ? 6 : recruitment.recruitment_stage_id,
        startDate: recruitment.start_date,
        clientInfoSentOn: recruitment.client_info_sent_on,
        sentToCustomerOn: recruitment.sent_to_client_on,
        preparationEmail: recruitment.preparation_email,
        preparationInterview: recruitment.preparation_interview,
        interviewDate: recruitment.interview_date,
        offerReceived: recruitment.offer_received,
        offerReceivedDate: recruitment.offer_received_date,
        offerStatusId: recruitment.offer_status_id,
        offerAcceptanceDate: recruitment.offer_acceptance_date,
        offerRejectedBy: recruitment.offer_rejected_by,
        rejectedOn: recruitment.rejected_on,
        rejectedInStage: recruitment.rejected_in_stage,
        startingDate: recruitment.starting_date,
        flightDate: recruitment.flight_date,
        attorneyContactId: recruitment.attorney_contact_id,
      }));
      handleCloseModal();
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: `${t('deleted', { keyPrefix: 'common' })}!`,
      text: t('are-you-sure-you-want-to-delete-it', { keyPrefix: 'common' }),
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: t('delete', { keyPrefix: 'common' }),
    }).then(() => {
      dispatch(deleteCandidateRecruitment({
        id: recruitment.id,
        candidateId: uuid,
      }));
    });
  };

  const handleAddDocument = () => {
    if (recruitment !== undefined) {
      dispatch(assignCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: selectedDoc,
      }));
      setSelectedDoc(null);
    }
  };

  const handleDeleteDocument = (id) => {
    if (recruitment !== undefined) {
      dispatch(deleteCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
      }));
    }
  };

  const handleUploadDocument = (files, id) => {
    if (recruitment !== undefined) {
      dispatch(uploadCandidateRecruitmentDocument({
        candidateId: uuid,
        recruitmentId: recruitment.id,
        documentId: id,
        file: files[0],
      }));
    }
  };

  const handleRejectedByCandidate = () => {
    setRecruitment({
      ...recruitment,
      recruitment_stage_id: 6,
      offer_rejected_by: 1,
      rejected_on: dayjs().format('YYYY-MM-DD'),
      rejected_in_stage: 1,
      changed: true,
    });
  };

  const handleRejectedByClient = () => {
    setRecruitment({
      ...recruitment,
      recruitment_stage_id: 6,
      offer_rejected_by: 2,
      rejected_on: dayjs().format('YYYY-MM-DD'),
      rejected_in_stage: 2,
      changed: true,
    });
  };

  if (saved) {
    dispatch(setSaved(false));
    savedCandidateMessage(t);
  }
  if (failed) {
    dispatch(setFailed(false));
    failedCandidateMessage(t, errors);
  }

  return (
    <SuiBox p={2}>
      {Object.keys(recruitments).length > 0
        && recruitments.map((a, i) => (
          <div key={`application${i}`}>
            <ApplicationPositionCard
              position={a}
              openFunction={(e) => handleOpenModal(e, a.id)}
            />
            <Divider />
          </div>
        ))}
      <DefaultModal openModal={openModal} closeFunc={() => setOpenModal(false)}>
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
          spacing={1}
        >
          <Grid item md={12}>
            <SuiTypography variant="h2">{position.name}</SuiTypography>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={3}>
            <OutlineTable
              dateOpened={position.date_opened}
              state={position.state}
              city={position.city}
              tags={position.tags}
            />
          </Grid>
          <Grid item md={9}>
            <Grid
              container
              spacing={2}
            >
              <Grid item md={12}>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                  mb={2}
                >
                  <Grid item>
                    <FormSelect
                      label={t('stage')}
                      options={stages}
                      value={
                        findSelectValue(stages, recruitment.recruitment_stage_id)
                      }
                      onChange={(e) => {
                        setValue('recruitment_stage_id', e.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {recruitment.recruitment_stage_id === 1 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('application-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('start_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.start_date)}
                    />
                  </Grid>
                  <Grid item md={10} />
                  <Grid item md={2}>
                    <FormField
                      label={t('client-info-sent-on')}
                      type="date"
                      onChange={(event) => {
                        setValue('client_info_sent_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.client_info_sent_on)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-info-to-candidate')}
                    </SuiButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={5}>
                    <SuiButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRejectedByCandidate}
                    >
                      {t('rejected-by-candidate')}
                    </SuiButton>
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 2 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('sent-to-client')}
                      type="date"
                      onChange={(event) => {
                        setValue('sent_to_client_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.sent_to_client_on)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-to-client')}
                    </SuiButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={5}>
                    <SuiButton
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRejectedByClient}
                    >
                      {t('rejected-by-client')}
                    </SuiButton>
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 3 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('preparation-email')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_email', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_email)}
                    />
                  </Grid>
                  <Grid item md={5} mt={3}>
                    <SuiButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSave}
                    >
                      {t('send-preparation-email')}
                    </SuiButton>
                  </Grid>
                  <Grid item md={5} />
                  <Grid item md={2}>
                    <FormField
                      label={t('preparation-interview')}
                      type="date"
                      onChange={(event) => {
                        setValue('preparation_interview', event.target.value);
                      }}
                      value={defaultValue(recruitment.preparation_interview)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormField
                      label={t('interview-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('interview_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.interview_date)}
                    />
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 4 && (
                <>
                  <Grid item md={2}>
                    <FormSelect
                      label={t('offer-received')}
                      placeholder={t('offer-received')}
                      options={offerReceived}
                      value={
                        findSelectValue(offerReceived, recruitment.offer_received)
                      }
                      onChange={(event) => {
                        setValue('offer_received', event.value);
                      }}
                    />
                  </Grid>
                  {recruitment.offer_received && (
                    <>
                      <Grid item md={2}>
                        <FormField
                          label={t('offer-received-date')}
                          type="date"
                          onChange={(event) => {
                            setValue('offer_received_date', event.target.value);
                          }}
                          value={defaultValue(recruitment.offer_received_date)}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormSelect
                          label={t('offer-status')}
                          placeholder={t('offer-status')}
                          options={offerStatuses}
                          value={
                            findSelectValue(offerStatuses, recruitment.offer_status_id)
                          }
                          onChange={(event) => {
                            setValue('offer_status_id', event.value);
                          }}
                        />
                      </Grid>
                      {recruitment.offer_status_id === 2 && (
                        <>
                          <Grid item md={2}>
                            <FormSelect
                              label={t('rejected-by')}
                              placeholder={t('rejected-by')}
                              options={rejectBy}
                              value={
                                findSelectValue(rejectBy, recruitment.offer_rejected_by)
                              }
                              onChange={(event) => {
                                setValue('offer_rejected_by', event.value);
                              }}
                            />
                          </Grid>
                          <Grid item md={2}>
                            <FormField
                              label={t('rejected-on')}
                              type="date"
                              onChange={(event) => {
                                setValue('rejected_on', event.target.value);
                              }}
                              value={defaultValue(recruitment.rejected_on)}
                            />
                          </Grid>
                        </>
                      )}
                      {recruitment.offer_status_id === 3 && (
                        <>
                          <Grid item md={2}>
                            <FormField
                              label={t('offer-acceptance-date')}
                              type="date"
                              onChange={(event) => {
                                setValue('offer_acceptance_date', event.target.value);
                              }}
                              value={defaultValue(recruitment.offer_acceptance_date)}
                            />
                          </Grid>
                          <Grid item md={2} />
                          {Object.keys(availDocs).length > 0 && (
                            <>
                              <Grid item md={4}>
                                <FormSelect
                                  label={t('assign-document')}
                                  placeholder={t('select-document')}
                                  options={availDocs}
                                  onChange={(e) => {
                                    setSelectedDoc(e.value);
                                  }}
                                />
                              </Grid>
                              <Grid item md={3} mt={5}>
                                <IconButton
                                  aria-label="add"
                                  onClick={handleAddDocument}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                            </>
                          )}
                          {Object.keys(assignedDocs).length > 0
                            && assignedDocs.map((f, j) => (
                              <Grid item md={12} pr={1}>
                                <DefaultDocumentCard
                                  key={`evaluation-document-${j}`}
                                  id={f.id}
                                  candidateId={uuid}
                                  description={f.document.description}
                                  title={f.document.name}
                                  uploadedSub={f.file_url !== undefined && f.file_url !== null
                                    ? t('uploaded', { keyPrefix: 'common' }) : undefined}
                                  expanded={false}
                                  fileUrl={f.file_url}
                                  filesLimit={f.document.uploads_number}
                                  changeFunc={(files) => {
                                    handleUploadDocument(files, f.id);
                                  }}
                                  removeFunc={() => {
                                    handleDeleteDocument(f.id);
                                  }}
                                />
                              </Grid>
                            ))}
                          <Grid item md={6} pr={1} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {recruitment.recruitment_stage_id === 5 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('starting-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('starting_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.starting_date)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormField
                      label={t('flight-date')}
                      type="date"
                      onChange={(event) => {
                        setValue('flight_date', event.target.value);
                      }}
                      value={defaultValue(recruitment.flight_date)}
                    />
                  </Grid>
                  <Grid item md={8} />
                  <Grid item md={6}>
                    <FormSelect
                      label={t('attorney-in-charge')}
                      placeholder={t('select-attorney')}
                      options={attorneyContacts}
                      value={
                        findSelectValue(attorneyContacts, recruitment.attorney_contact_id)
                      }
                      onChange={(event) => {
                        setValue('attorney_contact_id', event.value);
                      }}
                    />
                  </Grid>
                </>
              )}
              {recruitment.recruitment_stage_id === 6 && (
                <>
                  <Grid item md={2}>
                    <FormField
                      label={t('rejected-on')}
                      type="date"
                      onChange={(event) => {
                        setValue('rejected_on', event.target.value);
                      }}
                      value={defaultValue(recruitment.rejected_on)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormSelect
                      label={t('rejected-by')}
                      placeholder={t('rejected-by')}
                      options={rejectBy}
                      value={
                        findSelectValue(rejectBy, recruitment.offer_rejected_by)
                      }
                      onChange={(event) => {
                        setValue('offer_rejected_by', event.value);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormSelect
                      label={t('stage')}
                      options={stages}
                      value={
                        findSelectValue(stages, recruitment.rejected_in_stage)
                      }
                      onChange={(e) => {
                        setValue('rejected_in_stage', e.value);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              spacing={3}
            >
              <Grid item>
                <SuiButton
                  variant="gradient"
                  color="error"
                  size="small"
                  onClick={handleDelete}
                >
                  {t('delete', { keyPrefix: 'common' })}
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleSave}
                >
                  {t('save', { keyPrefix: 'common' })}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DefaultModal>
    </SuiBox>
  );
}

export default JobApplications;
