import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'reducers/authSlice';
import userReducer from 'reducers/usersSlice';
import candidateReducer from 'reducers/candidatesSlice';
import commonReducer from 'reducers/commonSlice';
import serviceReducer from 'reducers/servicesSlice';
import orderReducer from 'reducers/ordersSlice';
import paymentPlansReducer from 'reducers/paymentPlansSlice';
import accountReducer from 'reducers/accountsSlice';
import invoiceReducer from 'reducers/invoicesSlice';
import paymentsReducer from 'reducers/paymentsSlice';
import invoicePaymentReducer from 'reducers/invoicePaymentsSlice';
import documentsReducer from 'reducers/documentsSlice';
import payeeReducer from 'reducers/payeesSlice';
import expensesReducer from 'reducers/expenseSlice';
import paymentMethodReducer from 'reducers/paymentMethodSlice';
import emailListsReducer from 'reducers/emailListsSlice';
import availabilityReducer from 'reducers/availabilitiesSlice';
import agreementsReducer from 'reducers/agreementsSlice';
import templatesReducer from 'reducers/templatesSlice';
import fingerprintsReducer from 'reducers/fingerprintsSlice';
import rolesReducer from 'reducers/roleSlice';
import permissionsReducer from 'reducers/permisionsSlice';
import orderTemplatesReducer from 'reducers/orderTemplatesSlice';
import clientsReducer from 'reducers/clientSlice';
import positionListingsReducer from 'reducers/positionListingsSlice';
import reportsReducer from 'reducers/reportsSlice';
import recruiterReducer from 'reducers/recruiterSlice';
import bsrReducer from 'reducers/bsrSlice';
import vendorReducer from 'reducers/vendorSlice';
import attorneyReducer from 'reducers/attorneySlice';

const reducer = {
  auth: authReducer,
  user: userReducer,
  candidate: candidateReducer,
  common: commonReducer,
  service: serviceReducer,
  order: orderReducer,
  plan: paymentPlansReducer,
  account: accountReducer,
  invoice: invoiceReducer,
  payment: paymentsReducer,
  invoicePayment: invoicePaymentReducer,
  document: documentsReducer,
  payee: payeeReducer,
  expense: expensesReducer,
  paymentMethod: paymentMethodReducer,
  emailList: emailListsReducer,
  availability: availabilityReducer,
  agreement: agreementsReducer,
  template: templatesReducer,
  fingerprint: fingerprintsReducer,
  role: rolesReducer,
  permission: permissionsReducer,
  orderTemplate: orderTemplatesReducer,
  client: clientsReducer,
  positionListing: positionListingsReducer,
  reports: reportsReducer,
  recruiter: recruiterReducer,
  bsr: bsrReducer,
  vendor: vendorReducer,
  attorney: attorneyReducer,
};

export const store = configureStore({
  reducer,
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: [
        'payload.config.adapter',
        'payload.config.transformRequest',
        'payload.config.transformResponse',
        'payload.config.transformResponse',
        'payload.config.validateStatus',
        'payload.request',
      ],
    },
  }),
});

export default store;
